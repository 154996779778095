@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

.chat-logo-container {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  position: relative;
  z-index: 1;
}

.chat-logo {
  height: 80px;
  width: auto;
}

.chat-content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.chat-history {
  flex: 1 1;
  overflow-y: auto;
  padding: 1rem;
  padding-bottom: 70px; /* Adjust based on your input field height */
}

.chat-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--background-color);
  z-index: 10;
  padding: 10px;
  border-top: 1px solid var(--border-color);
}

.predefined-questions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-top: 1px solid var(--border-color);
}

.question-button {
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 0.8em;
  color: var(--text-color);
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.question-button:hover {
  background-color: rgba(255, 215, 0, 0.3);
}

.chat-input-container {
  display: flex;
  align-items: center;
}

.chat-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  font-size: 16px;
}

.send-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  background-color: var(--accent-yellow);
  color: var(--main-blue);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.chat-message-container {
  margin-bottom: 1rem;
  width: 100%;
}

.user-message {
  max-width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 18px;
  margin-bottom: 0.5rem;
  background-color: var(--user-message-bg);
  color: var(--text-color);
  align-self: flex-end;
  margin-left: auto;
  margin-right: 0;
  box-sizing: border-box;
  font-size: 0.95em;
}

.bot-message-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  width: 100%;
}

.bot-logo {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
  border-radius: 50%;
  flex-shrink: 0;
}

.bot-message {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.bot-message ul {
  padding-left: 20px;
  margin: 10px 0;
}

.bot-message li {
  margin-bottom: 5px;
}

.disclaimer {
  font-size: 0.7em;
  color: var(--secondary-text-color);
  text-align: center;
  padding: 0.25rem 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-top: 1px solid var(--border-color);
}

/* Dark mode styles */
.dark-mode {
  background-color: #1a1a1a;
  color: #ffffff;
}

.dark-mode .predefined-questions {
  background-color: rgba(30, 30, 30, 0.6);
  border-top-color: #444;
}

.dark-mode .question-button {
  background-color: rgba(50, 50, 50, 0.8);
  border-color: #555;
  color: #e0e0e0;
}

.dark-mode .question-button:hover {
  background-color: rgba(70, 70, 70, 0.9);
}

.dark-mode .chat-input {
  background-color: #333;
  color: #ffffff;
  border-color: #444;
}

.dark-mode .send-button {
  background-color: #4a4a4a;
  color: #ffffff;
}

.dark-mode .disclaimer {
  background-color: rgba(255, 255, 255, 0.05);
  color: #aaaaaa;
}

.dark-mode .bot-message {
  background-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .user-message {
  background-color: rgba(255, 215, 0, 0.2);
}

/* Tablet and larger screens */
@media (min-width: 769px) {
  .chat-content {
    padding: 1rem 15%;
  }
  
  .user-message,
  .bot-message {
    max-width: 70%;
  }
}

/* Mobile devices */
@media (max-width: 768px) {
  .chat-logo {
    height: 60px;
  }

  .chat-logo-container {
    padding: 0.5rem 0;
  }

  .predefined-questions {
    grid-template-columns: 1fr 1fr;
  }

  .question-button {
    font-size: 0.7em;
    padding: 0.4rem;
  }
}

/* Very small screens */
@media (max-width: 320px) {
  .predefined-questions {
    grid-template-columns: 1fr;  /* Stack vertically on very small screens */
  }

  .question-button {
    font-size: 0.9em;  /* Slightly larger font for better readability */
    padding: 0.75rem;  /* Increased padding for easier tapping */
  }
}

/* iOS-specific fixes */
@supports (-webkit-touch-callout: none) {
  .chat-container {
    height: -webkit-fill-available;
  }
  
  .chat-content {
    padding-bottom: 60px;
  }

  .chat-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@supports (-webkit-touch-callout: none) {
  .chat-bottom {
    padding-bottom: env(safe-area-inset-bottom);
  }
}
.upload-container {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
}

.password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px;
}

.password-input {
    padding: 10px;
    margin-bottom: 10px;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submit-button, .upload-button, .create-event-button {
    padding: 10px 20px;
    background-color: #ffd633;
    color: #333;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.submit-button:hover, .upload-button:hover, .create-event-button:hover {
    background-color: #e6bf2d;
}

.drag-drop-area {
    border: 2px dashed #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
}

.drag-active {
    border-color: #ffd633;
}

.file-input {
    display: none;
}

.file-label {
    display: block;
    margin-bottom: 10px;
}

.select-file-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.select-file-button:hover {
    background-color: #e0e0e0;
}

.select-file-button svg {
    margin-right: 5px;
}

.event-form {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 20px;
}

.event-form input, .event-form textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.event-form textarea {
    min-height: 100px;
}
.about-us-wrapper {
    height: 100%;
    overflow-y: auto;
    padding: 0 20px;
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  .about-us-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 2rem;
    background-color: var(--background-color);
    color: var(--text-color);
    line-height: 1.6;
  }
  
  .about-us-title {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 2rem;
    text-align: center;
    border-bottom: 2px solid var(--border-color);
    padding-bottom: 1rem;
  }
  
  .section-title {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 1.5rem;
  }
  
  .vision-mission {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .vision, .mission {
    flex: 1 1;
    background-color: var(--chat-history-bg);
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .vision:hover, .mission:hover {
    transform: translateY(-5px);
  }
  
  .vision h3, .mission h3 {
    color: var(--accent-yellow);
    margin-bottom: 1rem;
  }
  
  .feature-list, .achievement-list {
    list-style-type: none;
    padding-left: 0;
  }
  
  .feature-list li, .achievement-list li {
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative;
  }
  
  .feature-list li:before, .achievement-list li:before {
    content: "•";
    color: var(--accent-yellow);
    position: absolute;
    left: 0;
  }
  
  .patriji-intro {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    margin-bottom: 2rem;
    align-items: flex-start;
  }
  
  .patriji-image {
    width: 300px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .patriji-bio {
    flex: 1 1;
  }
  
  .pssm-info, .achievements {
    background-color: var(--chat-history-bg);
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .key-message {
    display: flex;
    align-items: center;
    background-color: var(--bot-message-bg);
    padding: 1.5rem;
    border-radius: 8px;
    margin-top: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .key-message svg {
    margin-right: 1rem;
    color: var(--accent-yellow);
    flex-shrink: 0;
  }
  
  /* Dark mode specific adjustments */
  .dark-mode .about-us-wrapper,
  .dark-mode .about-us-container {
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  .dark-mode .about-us-title,
  .dark-mode .section-title {
    color: var(--primary-color);
  }
  
  .dark-mode .vision,
  .dark-mode .mission,
  .dark-mode .pssm-info,
  .dark-mode .achievements {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .dark-mode .key-message {
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  @media (max-width: 768px) {
    .vision-mission, .patriji-intro {
      flex-direction: column;
    }
    
    .patriji-image {
      width: 100%;
      max-width: 300px;
      margin: 0 auto 1rem;
    }
  }

  .version-number {
    text-align: center;
    padding: 1rem 0;
    font-size: 0.8rem;
    color: var(--secondary-text-color);
    border-top: 1px solid var(--border-color);
    margin-top: 2rem;
  }
:root {
  --background-gradient-light: #F5F5F5;
  --background-gradient-dark: #E0E0E0;
  --main-blue: #795548;
  --accent-yellow: #8BC34A;
  --accent-pink: #FF4081;
  --text-color: #212121;
  --secondary-text-color: #424242;
  --border-color: #BDBDBD;
  
  --background-color: var(--background-gradient-light);
  --primary-color: var(--main-blue);
  --chat-history-bg: rgba(255, 255, 255, 0.1);
  --bot-message-bg: rgba(121, 85, 72, 0.2);
  --user-message-bg: rgba(139, 195, 74, 0.2);
  --input-bg: rgba(255, 255, 255, 0.6);
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(to bottom right, var(--background-gradient-light), var(--background-gradient-dark));
  color: var(--text-color);
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.app-header {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: rgba(30, 144, 255, 0.2);
  border-bottom: 1px solid var(--border-color);
  height: 40px;
  transition: all 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: var(--background-color);
  padding-top: env(safe-area-inset-top);
  
}

.header-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  background: linear-gradient(45deg, var(--accent-yellow), var(--accent-pink));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.header-icons {
  display: flex;
  align-items: center;
}

.header-icon {
  color: var(--text-color);
  cursor: pointer;
}

.app-header.chat-started {
  justify-content: space-between;
}

.main-content {
  flex: 1 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 80;
}

.menu-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: rgba(30, 144, 255, 0.2);
  border: 1px solid var(--border-color);
  border-top: none;
  z-index: 1000;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  text-decoration: none;
  color: var(--text-color);
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.menu-item svg {
  margin-right: 0.5rem;
}

/* Dark mode styles */
.dark-mode {
  --background-gradient-light: #2C3E50;
  --background-gradient-dark: #1A2A3A;
  --main-blue: #3498DB;
  --text-color: #ECF0F1;
  --secondary-text-color: #BDC3C7;
  --chat-history-bg: rgba(0, 0, 0, 0.2);
  --bot-message-bg: rgba(52, 152, 219, 0.2);
  --user-message-bg: rgba(241, 196, 15, 0.2);
  --input-bg: rgba(0, 0, 0, 0.2);
}

.dark-mode .app-header {
  background-color: rgba(52, 152, 219, 0.2);
}

.dark-mode .menu-dropdown {
  background-color: rgba(52, 152, 219, 0.2);
}

.dark-mode .menu-item:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .app-header {
    padding: 0.25rem 0.5rem;
  }

  .header-title {
    font-size: 1rem;
  }

  .menu-dropdown {
    width: 100%;
    top: 35px;
  }
}

/* Transitions for smooth theme switching */
.app-container, .app-header, .menu-dropdown, .menu-item {
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Prevent body scrolling on iOS */
body {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
