@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

:root {
  --background-gradient-light: #F5F5F5;
  --background-gradient-dark: #E0E0E0;
  --main-blue: #795548;
  --accent-yellow: #8BC34A;
  --accent-pink: #FF4081;
  --text-color: #212121;
  --secondary-text-color: #424242;
  --border-color: #BDBDBD;
  
  --background-color: var(--background-gradient-light);
  --primary-color: var(--main-blue);
  --chat-history-bg: rgba(255, 255, 255, 0.1);
  --bot-message-bg: rgba(121, 85, 72, 0.2);
  --user-message-bg: rgba(139, 195, 74, 0.2);
  --input-bg: rgba(255, 255, 255, 0.6);
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(to bottom right, var(--background-gradient-light), var(--background-gradient-dark));
  color: var(--text-color);
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.app-header {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: rgba(30, 144, 255, 0.2);
  border-bottom: 1px solid var(--border-color);
  height: 40px;
  transition: all 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: var(--background-color);
  padding-top: env(safe-area-inset-top);
  
}

.header-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  background: linear-gradient(45deg, var(--accent-yellow), var(--accent-pink));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.header-icons {
  display: flex;
  align-items: center;
}

.header-icon {
  color: var(--text-color);
  cursor: pointer;
}

.app-header.chat-started {
  justify-content: space-between;
}

.main-content {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 80;
}

.menu-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: rgba(30, 144, 255, 0.2);
  border: 1px solid var(--border-color);
  border-top: none;
  z-index: 1000;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  text-decoration: none;
  color: var(--text-color);
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.menu-item svg {
  margin-right: 0.5rem;
}

/* Dark mode styles */
.dark-mode {
  --background-gradient-light: #2C3E50;
  --background-gradient-dark: #1A2A3A;
  --main-blue: #3498DB;
  --text-color: #ECF0F1;
  --secondary-text-color: #BDC3C7;
  --chat-history-bg: rgba(0, 0, 0, 0.2);
  --bot-message-bg: rgba(52, 152, 219, 0.2);
  --user-message-bg: rgba(241, 196, 15, 0.2);
  --input-bg: rgba(0, 0, 0, 0.2);
}

.dark-mode .app-header {
  background-color: rgba(52, 152, 219, 0.2);
}

.dark-mode .menu-dropdown {
  background-color: rgba(52, 152, 219, 0.2);
}

.dark-mode .menu-item:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .app-header {
    padding: 0.25rem 0.5rem;
  }

  .header-title {
    font-size: 1rem;
  }

  .menu-dropdown {
    width: 100%;
    top: 35px;
  }
}

/* Transitions for smooth theme switching */
.app-container, .app-header, .menu-dropdown, .menu-item {
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Prevent body scrolling on iOS */
body {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}