.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

.chat-logo-container {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  position: relative;
  z-index: 1;
}

.chat-logo {
  height: 80px;
  width: auto;
}

.chat-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.chat-history {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  padding-bottom: 70px; /* Adjust based on your input field height */
}

.chat-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--background-color);
  z-index: 10;
  padding: 10px;
  border-top: 1px solid var(--border-color);
}

.predefined-questions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-top: 1px solid var(--border-color);
}

.question-button {
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 0.8em;
  color: var(--text-color);
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.question-button:hover {
  background-color: rgba(255, 215, 0, 0.3);
}

.chat-input-container {
  display: flex;
  align-items: center;
}

.chat-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  font-size: 16px;
}

.send-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  background-color: var(--accent-yellow);
  color: var(--main-blue);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.chat-message-container {
  margin-bottom: 1rem;
  width: 100%;
}

.user-message {
  max-width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 18px;
  margin-bottom: 0.5rem;
  background-color: var(--user-message-bg);
  color: var(--text-color);
  align-self: flex-end;
  margin-left: auto;
  margin-right: 0;
  box-sizing: border-box;
  font-size: 0.95em;
}

.bot-message-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  width: 100%;
}

.bot-logo {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
  border-radius: 50%;
  flex-shrink: 0;
}

.bot-message {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.bot-message ul {
  padding-left: 20px;
  margin: 10px 0;
}

.bot-message li {
  margin-bottom: 5px;
}

.disclaimer {
  font-size: 0.7em;
  color: var(--secondary-text-color);
  text-align: center;
  padding: 0.25rem 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-top: 1px solid var(--border-color);
}

/* Dark mode styles */
.dark-mode {
  background-color: #1a1a1a;
  color: #ffffff;
}

.dark-mode .predefined-questions {
  background-color: rgba(30, 30, 30, 0.6);
  border-top-color: #444;
}

.dark-mode .question-button {
  background-color: rgba(50, 50, 50, 0.8);
  border-color: #555;
  color: #e0e0e0;
}

.dark-mode .question-button:hover {
  background-color: rgba(70, 70, 70, 0.9);
}

.dark-mode .chat-input {
  background-color: #333;
  color: #ffffff;
  border-color: #444;
}

.dark-mode .send-button {
  background-color: #4a4a4a;
  color: #ffffff;
}

.dark-mode .disclaimer {
  background-color: rgba(255, 255, 255, 0.05);
  color: #aaaaaa;
}

.dark-mode .bot-message {
  background-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .user-message {
  background-color: rgba(255, 215, 0, 0.2);
}

/* Tablet and larger screens */
@media (min-width: 769px) {
  .chat-content {
    padding: 1rem 15%;
  }
  
  .user-message,
  .bot-message {
    max-width: 70%;
  }
}

/* Mobile devices */
@media (max-width: 768px) {
  .chat-logo {
    height: 60px;
  }

  .chat-logo-container {
    padding: 0.5rem 0;
  }

  .predefined-questions {
    grid-template-columns: 1fr 1fr;
  }

  .question-button {
    font-size: 0.7em;
    padding: 0.4rem;
  }
}

/* Very small screens */
@media (max-width: 320px) {
  .predefined-questions {
    grid-template-columns: 1fr;  /* Stack vertically on very small screens */
  }

  .question-button {
    font-size: 0.9em;  /* Slightly larger font for better readability */
    padding: 0.75rem;  /* Increased padding for easier tapping */
  }
}

/* iOS-specific fixes */
@supports (-webkit-touch-callout: none) {
  .chat-container {
    height: -webkit-fill-available;
  }
  
  .chat-content {
    padding-bottom: 60px;
  }

  .chat-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@supports (-webkit-touch-callout: none) {
  .chat-bottom {
    padding-bottom: env(safe-area-inset-bottom);
  }
}