.upload-container {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
}

.password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px;
}

.password-input {
    padding: 10px;
    margin-bottom: 10px;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submit-button, .upload-button, .create-event-button {
    padding: 10px 20px;
    background-color: #ffd633;
    color: #333;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.submit-button:hover, .upload-button:hover, .create-event-button:hover {
    background-color: #e6bf2d;
}

.drag-drop-area {
    border: 2px dashed #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
}

.drag-active {
    border-color: #ffd633;
}

.file-input {
    display: none;
}

.file-label {
    display: block;
    margin-bottom: 10px;
}

.select-file-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.select-file-button:hover {
    background-color: #e0e0e0;
}

.select-file-button svg {
    margin-right: 5px;
}

.event-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.event-form input, .event-form textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.event-form textarea {
    min-height: 100px;
}