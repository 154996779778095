.about-us-wrapper {
    height: 100%;
    overflow-y: auto;
    padding: 0 20px;
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  .about-us-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 2rem;
    background-color: var(--background-color);
    color: var(--text-color);
    line-height: 1.6;
  }
  
  .about-us-title {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 2rem;
    text-align: center;
    border-bottom: 2px solid var(--border-color);
    padding-bottom: 1rem;
  }
  
  .section-title {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 1.5rem;
  }
  
  .vision-mission {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .vision, .mission {
    flex: 1;
    background-color: var(--chat-history-bg);
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .vision:hover, .mission:hover {
    transform: translateY(-5px);
  }
  
  .vision h3, .mission h3 {
    color: var(--accent-yellow);
    margin-bottom: 1rem;
  }
  
  .feature-list, .achievement-list {
    list-style-type: none;
    padding-left: 0;
  }
  
  .feature-list li, .achievement-list li {
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative;
  }
  
  .feature-list li:before, .achievement-list li:before {
    content: "•";
    color: var(--accent-yellow);
    position: absolute;
    left: 0;
  }
  
  .patriji-intro {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
    align-items: flex-start;
  }
  
  .patriji-image {
    width: 300px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .patriji-bio {
    flex: 1;
  }
  
  .pssm-info, .achievements {
    background-color: var(--chat-history-bg);
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .key-message {
    display: flex;
    align-items: center;
    background-color: var(--bot-message-bg);
    padding: 1.5rem;
    border-radius: 8px;
    margin-top: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .key-message svg {
    margin-right: 1rem;
    color: var(--accent-yellow);
    flex-shrink: 0;
  }
  
  /* Dark mode specific adjustments */
  .dark-mode .about-us-wrapper,
  .dark-mode .about-us-container {
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  .dark-mode .about-us-title,
  .dark-mode .section-title {
    color: var(--primary-color);
  }
  
  .dark-mode .vision,
  .dark-mode .mission,
  .dark-mode .pssm-info,
  .dark-mode .achievements {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .dark-mode .key-message {
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  @media (max-width: 768px) {
    .vision-mission, .patriji-intro {
      flex-direction: column;
    }
    
    .patriji-image {
      width: 100%;
      max-width: 300px;
      margin: 0 auto 1rem;
    }
  }

  .version-number {
    text-align: center;
    padding: 1rem 0;
    font-size: 0.8rem;
    color: var(--secondary-text-color);
    border-top: 1px solid var(--border-color);
    margin-top: 2rem;
  }